export default [
  {
    path: "/auth/action",
    name: "authAction",
    component: () => import("@/components/AuthAction.vue"),
  },
  {
    path: "/signin",
    name: "signin",
    //   meta: {
    //     // requiresAuth: true
    //   },
    component: () =>
      import(
        /* webpackChunkName: "RobotDetail" */ "@/components/user/Signin.vue"
      ),
  },

  {
    path: "/signup",
    name: "signup",
    component: () =>
      import(
        /* webpackChunkName: "RobotDetail" */ "@/components/user/Signup.vue"
      ),
  },
  {
    path: "/signout",
    name: "signout",
    component: () =>
      import(
        /* webpackChunkName: "RobotDetail" */ "@/components/user/Signout.vue"
      ),
  },
  {
    path: "/signupstatus",
    name: "signupstatus",
    component: () =>
      import(
        /* webpackChunkName: "RobotDetail" */ "@/components/user/SignupStatus.vue"
      ),
  },
];
