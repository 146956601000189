export const APP_CONSTANTS = {
    APP: {
        PLATFORM: 'web',
    },
    AUTH: {
        FACEBOOK_SIGNIN_SCOPE: "email",
        GOOGLE_SIGNIN_SCOPE: "https://www.googleapis.com/auth/userinfo.email",
        SOCIAL_CLOSED_BY_USER_ERROR: "auth/popup-closed-by-user",
    },
    LOCAL_STORAGE: {
        SESSION_ID_KEY: "sst",

    },
    RESPONSE_CODE: {
        SUCCESS: 200,
        FAIL: 400,
        UNAUTH: 401,
        FORBIDDEN: 403,
        SERVER_FAIL: 500,
    }
}