import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

import router from "@/router";
import { store } from "@/store";
import { initializeApp } from "firebase/app";
// import { generateUUID } from "./services/localStorage";
import i18n from "@/plugins/i18n";
import { setupCalendar } from "v-calendar";
import appPlugin from "./utils/plugins/appPlugin";
import langHelperPlugin from "@/utils/plugins/langHelperPlugin";
// firebase config
const config = {
  apiKey: "AIzaSyAuCc61LPH2OJpUKH0DOHM3vcKDY11vjPo",
  authDomain: "app.crypto-mation.com",
  projectId: "crytosystem-4d333",
  storageBucket: "crytosystem-4d333.appspot.com",
  messagingSenderId: "842331751407",
  appId: "1:842331751407:web:4469f2eeb90d092ffc2c1a",
  measurementId: "G-2JMZE9JWCK",
};

setupCalendar({
  componentPrefix: "vc",
});

Vue.config.productionTip = false;

Vue.use(appPlugin);
Vue.use(langHelperPlugin);

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
  created() {
    initializeApp(config);
    // generateUUID();
    // if (store.getters.isSessionTokenSet) {
    //   store.dispatch('autoLogin')
    // }
  },
}).$mount("#app");
