const UUID_KEY = "udt";

export function getLocalStorage(key) {
  return window.localStorage.getItem(key);
}

export function setLocalStorage(key, value) {
  return window.localStorage.setItem(key, value);
}

export function removeLocalStorage(key) {
  return window.localStorage.removeItem(key);
}

export function generateUUID() {
  let _uuid = getLocalStorage(UUID_KEY);
  if (_uuid === null) {
    _uuid = self.crypto.randomUUID();
    setLocalStorage(UUID_KEY, _uuid);
  }
}

export function getUUID() {
  let _uuid = getLocalStorage(UUID_KEY);
  if (_uuid === null) {
    let _uuid = self.crypto.randomUUID();
    setLocalStorage(UUID_KEY, _uuid);
    return _uuid;
  }
  return _uuid;
}

export function setUUID_Directly(uuid) {
  setLocalStorage(UUID_KEY, uuid);
}

export function getUUID_Directly() {
  return getLocalStorage(UUID_KEY) || null;
}
