export default [
  {
    path: "/tradingbot/",
    name: "robot-list",
    component: () => import("@/components/robot/RobotList.vue"),
  },
  {
    path: "/robot/",
    name: "robot-detail",
    //   meta: {
    //     // requiresAuth: true
    //   },
    component: () => import("@/components/robot/RobotDetail.vue"),
  },
  {
    path:"/paymethod/",
    name: "ChillPayMerchantPage",
    component: () => import("@/components/robot/ChillPayMerchantPage.vue"),
  },
  {
    path:"/paymentstatus/",
    name: "ChillPayMerchantStatusPage",
    component: () => import("@/components/robot/ChillPayMerchantStatusPage.vue"),
  }
];

// /* webpackChunkName: "RobotDetail" */
