import * as types from "@/store/mutation-types";
import { APP_CONSTANTS } from "../../utils/constants";
import {
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
} from "@/services/localStorage";
import { authHttpClientPost, authHttpClientGet } from "@/services/api/axios";
import { getUUID } from "@/services/localStorage";
import Bowser from "bowser";
import router from "@/router";

const getters = {
  user: (state) => state.user,
  sessionToken: (state) => state.sessionToken,
  isSessionTokenSet: (state) => state.isSessionTokenSet,
};

const actions = {
  async userLogin({ commit }) {
    // return new Promise((resolve, reject) => {
    try {
      // get uuid
      const browserDetail = Bowser.parse(window.navigator.userAgent);
      const uuid = getUUID();
      let payload = {
        deviceId: uuid,
        devicePlatform: APP_CONSTANTS.APP.PLATFORM,
        deviceOs: `${browserDetail.os.name} ${browserDetail.os.versionName}`,
      };

      let res_data = await authHttpClientPost("/user/login", payload, false);
      if (res_data["code"] == 200) {
        // save session to localstorage
        commit(types.SAVE_SESSION_TOKEN, res_data["data"]["sessionId"]);
        setLocalStorage(APP_CONSTANTS.LOCAL_STORAGE.SESSION_ID_KEY, res_data["data"]["sessionId"]);
      }
      return res_data;
    } catch (error) {
      return error;
    }
  },
  async getUserInfo({ commit }) {
    try {
      //
      let res_data = await authHttpClientGet("/user/info");
      if (res_data["code"] == 200) {
        // console.log(res_data)

        let loginType = "Unknow";
        if (res_data["data"]["sign_in_provider"] == "password") {
          loginType = "Email";
        } else if (res_data["data"]["sign_in_provider"] == "facebook.com") {
          loginType = "Facebook";
        } else if (res_data["data"]["sign_in_provider"] == "google.com") {
          loginType = "Google";
        } else if (res_data["data"]["sign_in_provider"] == "apple.com") {
          loginType = "AppleID";
        } else {
          loginType = "Unknow";
        }

        commit(types.SAVE_USER, {
          name: res_data["data"]["email"].substring(0, 2).toUpperCase(),
          email: res_data["data"]["email"],
          verified: res_data["data"]["verified"],
          sign_in_provider: loginType,
          defaultRefCode: res_data["data"]["default_ref_code"],
        });
      } else {
        removeLocalStorage(APP_CONSTANTS.LOCAL_STORAGE.SESSION_ID_KEY);
        commit(types.LOGOUT);
        router.push({
          path: "/signout",
        });
      }
      return res_data;
    } catch (error) {
      removeLocalStorage(APP_CONSTANTS.LOCAL_STORAGE.SESSION_ID_KEY);
      commit(types.LOGOUT);
      router.push({
        path: "/signout",
      });
    }
  },
  async getUserInfoOnly({ commit }) {
    try {
      let res_data = await authHttpClientGet("/user/info");
      if (res_data["code"] == 200) {
        commit(types.SAVE_USER, {
          defaultRefCode: res_data["data"]["default_ref_code"],
        });
      }
    } catch (error) {
      console.log(error);
    }
  },
  userLogout({ commit }, payload) {
    removeLocalStorage(APP_CONSTANTS.LOCAL_STORAGE.SESSION_ID_KEY);
    commit(types.LOGOUT);
    router.push({
      path: "/signout",
      query: { mode: payload },
    });
  },
};
//

const mutations = {
  [types.SAVE_SESSION_TOKEN](state, sessionToken) {
    state.sessionToken = sessionToken;
    state.isSessionTokenSet = true;
  },
  [types.LOGOUT](state) {
    state.user = null;
    state.sessionToken = null;
    state.isSessionTokenSet = false;
  },
  [types.SAVE_USER](state, user) {
    state.user = user;
  },
};

const state = {
  user: null,
  sessionToken: getLocalStorage(APP_CONSTANTS.LOCAL_STORAGE.SESSION_ID_KEY) || null,
  isSessionTokenSet: !!getLocalStorage(APP_CONSTANTS.LOCAL_STORAGE.SESSION_ID_KEY),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
