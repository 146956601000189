export default {
  install(Vue) {
    Vue.prototype.$changeLang = function (objData) {
      if (this.$store.getters.app_language == "en") {
        return objData.en;
      } else {
        return objData.th;
      }
    };
  },
};
