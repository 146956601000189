<template>
  <v-app-bar app dense v-if="showAppBar">
    <v-img
      class="mx-1"
      :src="require('../assets/app-bar-logo.png')"
      max-height="32"
      max-width="32"
      contain
      style="cursor: pointer"
      @click="goToRoot"
    ></v-img>
    <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
    <v-toolbar-title class="headline" style="cursor: pointer" @click="goToRoot">
      Crypto-Mation
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <!-- <v-menu v-if="isMobile"> </v-menu> -->
    <v-toolbar-items class="hidden-sm-and-down">
      <v-btn text :ripple="false" to="/tradingbot"> {{ $t("appbar.tradingBot") }} </v-btn>
      <v-btn text :ripple="false" to="/docs/registerbot">
        {{ $t("appbar.documents") }}
      </v-btn>
      <v-btn text to="/signin" v-if="!isLogin">
        {{ $t("auth.signIn") }}
      </v-btn>
      <LanguageFlatBtn />
      <!-- <v-btn id="getstartedBtn" text @click="toggleInstallAppDialog">
        Get started
      </v-btn> -->
    </v-toolbar-items>
    <!-- -->
    <v-menu class="hidden-md-and-up">
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon
          class="hidden-md-and-up"
          v-bind="attrs"
          v-on="on"
        ></v-app-bar-nav-icon>
      </template>
      <!-- -->
      <v-list>
        <v-list-item to="/tradingbot" link>
          <v-list-item-title>{{ $t("appbar.tradingBot") }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/docs/registerbot" link>
          <v-list-item-title>{{ $t("appbar.documents") }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!isLogin" to="/signin">
          <v-list-item-title>{{ $t("auth.signIn") }}</v-list-item-title>
        </v-list-item>
        <LanguageFlatItemList />
        <!-- <v-list-item @click="toggleInstallAppDialog">
          <v-list-item-title>Get started</v-list-item-title>
        </v-list-item> -->
      </v-list>
    </v-menu>

    <v-menu bottom min-width="200px" rounded offset-y v-if="isLogin">
      <template v-slot:activator="{ on }">
        <v-btn icon x-large v-on="on">
          <v-avatar color="" size="40">
            <span class="white--text text-h5">{{ userData.name }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-list-item-content class="justify-center">
          <div class="mx-auto text-center">
            <!-- <h3>johnny</h3> -->
            <p class="text-caption mt-2">{{ userData.email }}</p>
            <div class="text-caption">
              Login Type:
              {{ userData.sign_in_provider }}
              <div
                class="green--text"
                v-if="userData.verified && userData.sign_in_provider == 'Email'"
              >
                (Verified)
              </div>
              <div
                class="red--text"
                v-if="
                  !userData.verified && userData.sign_in_provider == 'Email'
                "
              >
                (Not Verified)
              </div>
            </div>

            <div
              v-if="!userData.verified && userData.sign_in_provider == 'Email'"
            >
              <v-divider class="my-3"></v-divider>
              <v-btn
                depressed
                rounded
                text
                @click.prevent="goToPage('/verifyemail')"
              >
                Verify Email
              </v-btn>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn
              depressed
              rounded
              text
              @click.prevent="goToPage('/subscriptions')"
            >
             {{$t("appbar.MySubscriptions")}}
            </v-btn>
            <div v-if="userData.sign_in_provider == 'Email'">
              <v-divider class="my-3"></v-divider>
              <v-btn
                depressed
                rounded
                text
                @click.prevent="goToPage('/changepassword')"
              >
                {{ $t("appbar.changePassword") }}
              </v-btn>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn depressed rounded text @click.prevent="signOut">
              {{$t("appbar.SignOut")}}
            </v-btn>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>
    <!-- -->
  </v-app-bar>
</template>

<script>
import { mapActions } from "vuex";
import LanguageFlatBtn from "./subcomponents/languageFlatBtn.vue";
import LanguageFlatItemList from "./subcomponents/languageFlatList";

export default {
  name: "AppNavbar",
  components: {
    LanguageFlatItemList,
    LanguageFlatBtn,
  },
  data: () => ({
    menuList: [
      {
        name: "Documents",
        link: "/docs/registerbot",
      },
      {
        name: "get started",
        link: "",
      },
    ],
    showAppBar: false,
  }),
  mounted() {},
  watch: {
    $route(to) {
      if (to.meta.disabledAppFooter) {
        this.showAppBar = false;
      } else {
        this.showAppBar = true;
      }
    },
  },
  computed: {
    isLogin() {
      if (this.$store.getters.isSessionTokenSet) {
        this.getUserInfo();
      }
      return this.$store.getters.isSessionTokenSet;
    },
    userData() {
      let user_data = this.$store.getters.user || {
        email: "",
        name: "",
        verified: false,
        sign_in_provider: null,
      };
      return user_data;
    },
    // isMobile() {
    //   return this.$vuetify.breakpoint.xsOnly;
    // },
  },
  methods: {
    ...mapActions(["userLogout", "getUserInfo"]),
    toggleInstallAppDialog() {
      this.$store.dispatch("setDisplayInstallAppDialog");
    },
    goToRoot() {
      if (this.$router.history.current.path != "/tradingbot") {
        this.$router.push({ path: "/tradingbot" });
      }
    },
    goToPage(gotoPath) {
      this.$router.push({ path: gotoPath });
    },
    signOut() {
      this.userLogout("default");
    },
  },
};
</script>
<style scoped>
#getstartedBtn {
  background: green;
}
</style>
