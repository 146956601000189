import axios from "axios";
import _ from "lodash";
import { getLocalStorage } from "@/services/localStorage";
import { getAuth } from "firebase/auth";

const _baseURL = process.env.VUE_APP_API_URL;
const SESSION_ID_KEY = "sst";
// get / post / create instance
const createAxiosInstance = () => axios.create({ baseURL: _baseURL });
//
// 401 unauthen;

export async function httpExternalClientGet(uri) {
  try {
    const resp_data = await axios.get(uri);
    console.log(resp_data);
    // if (data["code"] === 200) {
    //   return data["data"];
    // } else {
    //   return _.pick(data, ["code", "message"]);
    // }
  } catch (error) {
    // if (error.response) {
    //   return { code: error.response.status, data: error.response.data };
    // }
    // console.log(error);
  }
}

export async function httpClientGet(uri) {
  try {
    const axiosInstance = createAxiosInstance();
    const { data } = await axiosInstance.get(uri);
    if (data["code"] === 200) {
      return data["data"];
    } else {
      return _.pick(data, ["code", "message"]);
    }
  } catch (error) {
    if (error.response) {
      return { code: error.response.status, data: error.response.data };
    }
    // console.log(error);
  }
}

export async function httpClientPost(uri, payload) {
  try {
    const axiosInstance = createAxiosInstance();
    const { data } = await axiosInstance.post(uri, payload);
    if (data["code"] === 200) {
      return _.pick(data, ["code", "data"]);
    } else {
      return _.pick(data, ["code", "message"]);
    }
  } catch (error) {
    if (error.response) {
      // console.log({
      //   code: error.response.status,
      //   message: error.response.data.message,
      // });
      return {
        code: error.response.status,
        message: error.response.data.message,
      };
    }
    // console.log(error);
  }
}

// https://github.com/vuejs/vuefire/issues/18
export async function authHttpClientPost(uri, payload, checkSession = true) {
  return new Promise(function (resolve, reject) {
    const axiosInstance = createAxiosInstance();
    const auth = getAuth();

    auth.onAuthStateChanged(async (user) => {
      if (user !== null) {
        let access_token = await user.getIdToken(false);
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${access_token}`;

        if (checkSession) {
          const sessionId = getLocalStorage(SESSION_ID_KEY);
          axiosInstance.defaults.headers.common["sessionid"] = `${sessionId}`;
        }

        try {
          const { data } = await axiosInstance.post(uri, payload);
          //
          if (data["code"] === 200) {
            resolve(_.pick(data, ["code", "data"]));
          } else {
            resolve(_.pick(data, ["code", "message"]));
          }
        } catch (error) {
          if (error.response) {
            reject({ code: error.response.status, data: error.response.data });
          } else {
            reject({ code: 400, data: "Unauthorized" });
          }
        }
      } else {
        return reject({ code: 400, data: "Unauthorized by platform" });
      }
    });
  });
}

export async function authHttpClientGet(uri) {
  return new Promise(function (resolve, reject) {
    const axiosInstance = createAxiosInstance();
    const auth = getAuth();
    auth.onAuthStateChanged(async (user) => {
      if (user !== null) {
        let access_token = await user.getIdToken(false);
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${access_token}`;

        const sessionId = getLocalStorage(SESSION_ID_KEY);
        axiosInstance.defaults.headers.common["sessionid"] = `${sessionId}`;

        try {
          const { data } = await axiosInstance.get(uri);
          //
          if (data["code"] === 200) {
            resolve(_.pick(data, ["code", "data"]));
          } else {
            return resolve(_.pick(data, ["code", "message"]));
          }
        } catch (error) {
          if (error.response) {
            reject({ code: error.response.status, data: error.response.data });
          } else {
            reject({ code: 400, data: "Unauthorized" });
          }
        }
      } else {
        return reject({ code: 400, data: "Unauthorized by platform" });
      }
    });
  });
}

export async function authHttpClientPut(uri, payload, checkSession = true) {
  return new Promise(function (resolve, reject) {
    const axiosInstance = createAxiosInstance();
    const auth = getAuth();

    auth.onAuthStateChanged(async (user) => {
      if (user !== null) {
        let access_token = await user.getIdToken(false);
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${access_token}`;

        if (checkSession) {
          const sessionId = getLocalStorage(SESSION_ID_KEY);
          axiosInstance.defaults.headers.common["sessionid"] = `${sessionId}`;
        }

        try {
          const { data } = await axiosInstance.put(uri, payload);
          //
          if (data["code"] === 200) {
            resolve(_.pick(data, ["code", "data", "message"]));
          } else {
            resolve(_.pick(data, ["code", "message"]));
          }
        } catch (error) {
          if (error.response) {
            reject({ code: error.response.status, data: error.response.data });
          } else {
            reject({ code: 400, data: "Unauthorized" });
          }
        }
      } else {
        return reject({ code: 400, data: "Unauthorized by platform" });
      }
    });
  });
}
