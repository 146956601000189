<template>
  <v-list-item v-on:click="changeLang()">
    <v-list-item-title>
      <CountryFlag :country="flagLang" size="normal" />
    </v-list-item-title>
  </v-list-item>
</template>

<script>
import { mapActions } from "vuex";
import CountryFlag from "vue-country-flag";
export default {
  name: "LanguageFlatItem",
  components: {
    CountryFlag,
  },
  data: () => ({
    lang: "",
    flagLang: "gb",
  }),
  methods: {
    ...mapActions(["setAppLanguage"]),
    changeFlagLang() {
      this.flagLang = this.lang == "en" ? "gb" : "th";
    },
    changeLang() {
      this.lang = this.lang == "en" ? "th" : "en";
      this.setAppLanguage(this.lang);
      this.changeFlagLang();
    },
  },
  mounted() {
    this.lang = this.$store.getters.app_language;
    this.changeFlagLang();
  },
};
</script>

<style scoped></style>
