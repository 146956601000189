export default [
  {
    path: "/docs",
    name: "documents",
    component: () => import("@/components/docs/DocumentsPage.vue"),
  },
  {
    path: "/docs/:section",
    name: "documents",
    component: () => import("@/components/docs/DocumentsPage.vue"),
  },
];

// /* webpackChunkName: "RobotDetail" */
