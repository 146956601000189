import { httpClientGet } from "@/services/api/axios";
import { getUUID_Directly, setUUID_Directly } from "../localStorage";

export async function syncUUID() {
  try {
    if (getUUID_Directly() === null) {
      let uuidData = await httpClientGet(`/tool/uuid`);
      setUUID_Directly(uuidData);
    }
  } catch (error) {
    setUUID_Directly(null);
    return error;
  }
}
